import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
  Grid,
  FormHelperText,
  Checkbox,
  Alert,
  AlertTitle,
  Chip,
  Popover,
  makeStyles
} from '@material-ui/core';
import { Publish } from '@material-ui/icons';

import { useBeforeunload } from 'react-beforeunload';

import { addMedicalCase } from 'redux/medical_cases/medical_cases.actions';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

import Pdf from 'static/terms_and_conditions.pdf';

import JSZip from 'jszip';
// import { saveAs } from 'file-saver';

import { uploadMedicalCase, uploadMRI } from '../../services/http/medical_case';

const tarMessage = 'Upload compressed file with a DICOM inside';
const folderMessage = 'Upload folder containing DICOM images';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: '2px',
    borderRadius: '4px'
  }
}));

const UploadCaseForm = ({
  onClose,
  isUploadingMriToServer,
  setUploadingMri
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  const [mriFile, setMriFile] = useState();
  const [mriFolder, setMriFolder] = useState();
  const [folderName, setFolderName] = useState('');
  const [isCompressing, setIsCompressing] = useState(false);
  const [compressingPercent, setCompressingPercent] = useState(0);
  const [snackBarKey, setSnackBarKey] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTarTootltip, setOpenTarTooltip] = useState(false);
  const [openFolderTootltip, setOpenFolderTooltip] = useState(false);
  // const [uploadingProgress, setUploadingProgress] = useState(0);

  const inputFile = useRef(null);
  const inputFolder = useRef(null);

  useBeforeunload(() => 'You’ll lose your data!');

  // const handleProgressChange = (currentProgress) => {
  //   if (currentProgress % 10 === 0) {
  //     setUploadingProgress(currentProgress);
  //   }
  // };

  const handleUploadCase = async (data) =>
    uploadMedicalCase(data)
      .then((newCase) => {
        setUploadingMri(true);
        const sbKey = enqueueSnackbar(
          'Under normal conditions upload takes about a minute. Please do not close your browser tab',
          {
            variant: 'info',
            preventDuplicate: true,
            persist: true,
            action: (key) => (
              <Button onClick={() => closeSnackbar(key)}>
                <Typography color="white">Close</Typography>
              </Button>
            )
          }
        );
        setSnackBarKey(sbKey);
        const toUpload = !!folderName && !!mriFolder ? mriFolder : mriFile;

        uploadMRI(newCase.id, toUpload)
          .then((caseWithMri) => {
            dispatch(addMedicalCase(caseWithMri));
            enqueueSnackbar(
              'Your case will be proccessed. We will notify you via email when it is ready.',
              { variant: 'info' }
            );
            onClose();
          })
          .catch((e) => {
            console.error(e?.response?.data?.message);
            enqueueSnackbar('Error uploading MRI.', { variant: 'error' });
          })
          .finally(() => {
            setUploadingMri(false);
            closeSnackbar(snackBarKey);
            setSnackBarKey(undefined);
          });
      })
      .catch((e) => {
        console.error(e?.response?.data?.message);
        enqueueSnackbar('Error uploading case.', { variant: 'error' });
      });

  const handleUnselectFile = () => {
    // Allows to select again
    inputFile.current.value = '';
    if (isUploadingMriToServer) return;
    setMriFile(undefined);
  };

  const handleUnselectFolder = () => {
    // Allows to select again
    inputFolder.current.value = '';
    if (isUploadingMriToServer) return;
    setMriFolder(undefined);
    setIsCompressing(false);
    setFolderName('');
  };

  const handleTarPopOverOpen = (e) => {
    if (e) {
      setAnchorEl(e.currentTarget);
    }
    setOpenTarTooltip(true);
  };

  const handleFolderPopOverOpen = (e) => {
    if (e) {
      setAnchorEl(e.currentTarget);
    }
    setOpenFolderTooltip(true);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
    setOpenTarTooltip(false);
    setOpenFolderTooltip(false);
  };

  const onUploadButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onUploadFolderButtonClick = () => {
    // `current` points to the mounted directory input element
    inputFolder.current.webkitdirectory = true;
    inputFolder.current.mozdirectory = true;
    inputFolder.current.click();
  };

  const handleUploadedFile = (event) => {
    setMriFolder(undefined);
    const file = event.target.files[0];
    setMriFile(file);
  };

  const handleUploadedFolder = async (event) => {
    setMriFile(undefined);
    const folder = event.target.files;
    setFolderName(
      folder[0]?.webkitRelativePath?.split('/')[0] || 'Folder uploaded'
    );
    // if (folder.length !== 160) {
    //   enqueueSnackbar('The uploaded folder should have 160 elements.', {
    //     variant: 'error'
    //   });
    //   return;
    // }
    const zip = new JSZip();
    for (let i = 0; i < folder.length; i++) {
      const element = folder[i];
      // 324KB = 331776B
      if (element.size < 330000 || element.size > 335000) {
        enqueueSnackbar(
          `Error in file ${element.name} size. Each file must be 324KB.`,
          {
            variant: 'error'
          }
        );
        return;
      }
      zip.file(element.name, element);
    }

    setIsCompressing(true);
    const compressed = await zip.generateAsync(
      {
        type: 'blob',
        compression: 'DEFLATE'
      },
      (metadata) => {
        if (metadata.percent % 10 === 0) {
          setCompressingPercent(metadata.percent);
        }
      }
    );

    setMriFolder(compressed);
    setIsCompressing(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        justifyContent: 'center',
        padding: '20px'
      }}
    >
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            name: '',
            age: 42,
            gender: 'male',
            weight: 1,
            diagnostic: 'Sagittal',
            captureDate: new Date(),
            policy: false
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(30),
            age: Yup.number()
              .moreThan(-1)
              .lessThan(120)
              .required('Age is required'),
            gender: Yup.string().oneOf(['male', 'female']),
            weight: Yup.number()
              .moreThan(0, 'Side value must be 1, 2 or 3')
              .lessThan(4, 'Side value must be 1, 2 or 3')
              .required('Side is required'),
            diagnostic: Yup.string().max(300).required('Diagnostic is required')
            // policy: Yup.bool().equals(
            //   true,
            //   'You must accept the Terms and Conditions.'
            // )
          })}
          onSubmit={handleUploadCase}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              onSubmit={async (data) => {
                await handleSubmit(data);
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography color="textPrimary" variant="h2">
                  Upload case
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Fill in with case data
                </Typography>
              </Box>

              <Alert severity="warning">
                <AlertTitle>MRI ACQUISITION INFORMATION:</AlertTitle>
                <p>
                  MRI data must be acquired using DESS imaging protocol with a
                  sagittal resolution of 384x384 pixels with 160 layers along
                  the orthogonal direction.
                </p>
              </Alert>

              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
                disabled={isSubmitting || isUploadingMriToServer}
              />
              <TextField
                error={Boolean(touched.age && errors.age)}
                fullWidth
                helperText={touched.age && errors.age}
                label="Age"
                margin="normal"
                name="age"
                onBlur={handleBlur}
                onChange={(v) => {
                  const numValue = +v.target.value;
                  if (Number.isNaN(numValue)) return;
                  setFieldValue('age', numValue);
                }}
                type="text"
                value={values.age}
                variant="outlined"
                required
                disabled={isSubmitting || isUploadingMriToServer}
              />
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="demo-simple-select-outlined-label">
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  error={touched.gender && errors.gender}
                  value={values.gender}
                  onChange={handleChange}
                  label="Gender"
                  name="gender"
                  required
                  disabled={isSubmitting || isUploadingMriToServer}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
              <TextField
                error={Boolean(touched.weight && errors.weight)}
                fullWidth
                helperText={touched.weight && errors.weight}
                label="Side (1=Right, 2=Left, 3=Unknown)"
                margin="normal"
                name="weight"
                onBlur={handleBlur}
                onChange={(v) => {
                  const numValue = +v.target.value;
                  if (Number.isNaN(numValue)) return;
                  setFieldValue('weight', numValue);
                }}
                type="text"
                value={values.weight}
                variant="outlined"
                required
                disabled={isSubmitting || isUploadingMriToServer}
              />
              <TextField
                error={Boolean(touched.diagnostic && errors.diagnostic)}
                fullWidth
                helperText={touched.diagnostic && errors.diagnostic}
                label="Sequences"
                margin="normal"
                name="diagnostic"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.diagnostic}
                variant="outlined"
                multiline
                rows={3}
                required
                disabled={isSubmitting || isUploadingMriToServer}
              />
              <input
                type="file"
                id="file"
                ref={inputFile}
                accept=".tar.gz,.zip"
                multiple={false}
                style={{ display: 'none' }}
                onChange={handleUploadedFile}
              />
              <input
                type="file"
                id="folder"
                ref={inputFolder}
                multiple={false}
                style={{ display: 'none' }}
                onChange={handleUploadedFolder}
              />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  py: 1
                }}
              >
                <Checkbox
                  checked={values.policy}
                  name="policy"
                  onChange={handleChange}
                />
                <Typography color="textSecondary" variant="body1">
                  I have read and accept the
                  <a href={Pdf} target="_blank" rel="noreferrer">
                    {' '}
                    Terms and Conditions
                  </a>
                </Typography>
              </Box>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>{errors.policy}</FormHelperText>
              )}
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sm={9} xs={9} mb={2}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Button
                      type="button"
                      onClick={onUploadButtonClick}
                      color="primary"
                      disabled={
                        isUploadingMriToServer ||
                        !values.policy ||
                        !!folderName ||
                        (!!mriFolder && !!folderName)
                      }
                      size="medium"
                      variant="contained"
                      style={{ marginRight: '1rem', paddingRight: '2.65rem' }}
                      onMouseEnter={handleTarPopOverOpen}
                      onMouseLeave={handlePopOverClose}
                    >
                      {isUploadingMriToServer ? (
                        'Uploading MRI'
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Publish
                            fontSize="small"
                            style={{ marginRight: '0.5rem' }}
                          />
                          DICOM tar
                        </Box>
                      )}
                    </Button>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '4px 0px'
                      }}
                    >
                      {mriFile?.name ? (
                        <Chip
                          variant="outlined"
                          onDelete={handleUnselectFile}
                          label={mriFile?.name}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          color="InfoText"
                        >
                          No file selected
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '1rem'
                      }}
                    >
                      {mriFile && isUploadingMriToServer && (
                        <CircularProgress size="3rem" />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sm={9} xs={9}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Button
                      type="button"
                      onClick={onUploadFolderButtonClick}
                      color="primary"
                      disabled={
                        isUploadingMriToServer ||
                        !values.policy ||
                        !!mriFile ||
                        isCompressing
                      }
                      size="medium"
                      variant="contained"
                      style={{ marginRight: '1rem' }}
                      onMouseEnter={handleFolderPopOverOpen}
                      onMouseLeave={handlePopOverClose}
                    >
                      {isUploadingMriToServer ? (
                        'Uploading MRI'
                      ) : isCompressing ? (
                        'Compressing Folder'
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Publish
                            fontSize="small"
                            style={{ marginRight: '0.5rem' }}
                          />
                          DICOM folder
                        </Box>
                      )}
                    </Button>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '4px 0px'
                      }}
                    >
                      {folderName ? (
                        <Chip
                          variant="outlined"
                          onDelete={handleUnselectFolder}
                          label={folderName}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          color="InfoText"
                        >
                          No folder selected
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '1rem'
                      }}
                    >
                      {((mriFolder && isUploadingMriToServer) ||
                        isCompressing) && (
                        <CircularProgress
                          size="3rem"
                          variant={
                            isCompressing ? 'determinate' : 'indeterminate'
                          }
                          value={compressingPercent}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}
              >
                <Button
                  color="primary"
                  disabled={
                    isSubmitting ||
                    isUploadingMriToServer ||
                    !values.policy ||
                    isCompressing ||
                    (!mriFile && (!mriFolder || !folderName))
                  }
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    marginRight: '1%'
                  }}
                >
                  {isUploadingMriToServer ? 'Uploading Case' : 'Upload Case'}
                </Button>
                <Button
                  color="secondary"
                  disabled={isSubmitting || isUploadingMriToServer}
                  size="large"
                  onClick={() => {
                    closeSnackbar(snackBarKey);
                    onClose();
                  }}
                  variant="contained"
                  style={{ marginLeft: '1%' }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={openTarTootltip}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopOverClose}
          disableRestoreFocus
        >
          <Typography px="1rem">{tarMessage}</Typography>
        </Popover>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={openFolderTootltip}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopOverClose}
          disableRestoreFocus
        >
          <Typography px="1rem">{folderMessage}</Typography>
        </Popover>
      </Container>
    </Box>
  );
};

UploadCaseForm.propTypes = {
  onClose: PropTypes.func,
  isUploadingMriToServer: PropTypes.bool,
  setUploadingMri: PropTypes.func
};

export default UploadCaseForm;
