import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { confirmPassword } from 'services/http/auth';
import { useSnackbar } from 'notistack';

// const REGEX_PASSWORD = RegExp(
//   '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|]).{8,32}$'
// );

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePassword = async (email, password, code) =>
    confirmPassword(email, password, code)
      .then(() => {
        enqueueSnackbar('Login with your new credentials.', {
          variant: 'info'
        });
        navigate('/login', { state: { email }, replace: true });
      })
      // TODO: move password validation to frontend
      .catch((e) => {
        if (e.response?.data?.message) {
          enqueueSnackbar(e.response.data.message, {
            variant: 'error',
            autoHideDuration: 6000
          });
        } else {
          enqueueSnackbar('An error ocurred. Please try again.', {
            variant: 'error'
          });
        }
      });

  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: location?.state?.email || '',
              code: '',
              password: '',
              passwordConfirmation: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              code: Yup.string().max(255).required('Code is required'),
              password: Yup.string()
                .min(8)
                // .matches(REGEX_PASSWORD, 'Password does not meet requirements.')
                .required('Password is required'),
              passwordConfirmation: Yup.string().oneOf(
                [Yup.ref('password'), null],
                'Passwords must match'
              )
            })}
            onSubmit={({ email, code, password }) =>
              handleChangePassword(email, password, code)
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                onSubmit={async (data) => {
                  await handleSubmit(data);
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Change your password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use the received code to change your password
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  helperText={touched.code && errors.code}
                  label="Code"
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(
                    touched.passwordConfirmation && errors.passwordConfirmation
                  )}
                  fullWidth
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                  label="Password Confirmation"
                  margin="normal"
                  name="passwordConfirmation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.passwordConfirmation}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Change Password
                  </Button>
                </Box>
                {/* <Typography color="textSecondary" variant="body1">
                  Have an account?
                  <Link component={RouterLink} to="/login" variant="h6">
                    Sign in
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ChangePassword;
