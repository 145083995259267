import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { confirmAccount, resendVerification } from 'services/http/auth';
import { useSnackbar } from 'notistack';

const ActivateAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const handleActivation = (email, activationCode) =>
    confirmAccount(email, activationCode.toString())
      .then(() => {
        enqueueSnackbar('Account activated with success.', {
          variant: 'success'
        });
        navigate('/login', { replace: true });
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          enqueueSnackbar(e.response.data.message, {
            variant: 'error',
            autoHideDuration: 6000
          });
        } else {
          enqueueSnackbar('An error ocurred. Please try again.', {
            variant: 'error'
          });
        }
      });

  const handleResendCode = (email) => {
    resendVerification(email)
      .then(() => {
        enqueueSnackbar('Activation code sent.', {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar('An error ocurred. Please try again.', {
          variant: 'error'
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>Activate Account</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: location?.state?.email || '',
              activationCode: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              activationCode: Yup.string().required(
                'Activation code is required'
              )
            })}
            onSubmit={async ({ email, activationCode }) =>
              handleActivation(email, activationCode)
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                onSubmit={async (data) => {
                  await handleSubmit(data);
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Activate your account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use the code you received via email to activate your new
                    account
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(
                    touched.activationCode && errors.activationCode
                  )}
                  fullWidth
                  helperText={touched.activationCode && errors.activationCode}
                  label="Activation Code"
                  margin="normal"
                  name="activationCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.activationCode}
                  variant="outlined"
                />
                {/* <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1
                  }}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography color="textSecondary" variant="body1">
                    I have read the{' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box> */}
                {/* {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )} */}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Activate
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Didn&apos;t get a code?
                  <Link
                    onClick={() => handleResendCode(values.email)}
                    variant="h6"
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    Resend code
                  </Link>
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  Already active?
                  <Link component={RouterLink} to="/login" variant="h6">
                    {' '}
                    Sign In
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ActivateAccount;
