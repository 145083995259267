import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  ButtonGroup,
  CircularProgress,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

// import { useSnackbar } from 'notistack';
import { TextWithLabel } from 'components/ui/TextWithLabel';
import { downloadFile } from 'utils/download';
import { UserRoles } from 'constants/userRoles';
import { useSelector } from 'react-redux';
// import { useState } from 'react';
import { useRef, useState } from 'react';
import { Download, Visibility, Publish } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { uploadSegData, runSegData, runProcessor, runDelete, runRadPDP, uploadFUSData } from '../../services/http/medical_case';

// import { Publish } from '@material-ui/icons';

const {
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_PARAVIEW_URL: PARAVIEW_URL
} = process.env;

const MedicalCaseInfo = ({ onClose, medicalCase }) => {
  // const { enqueueSnackbar } = useSnackbar();
  const [mriFile, setMriFile] = useState();
  const inputFile = useRef(null);
  const [FUSFile, setFUSFile] = useState();
  const inputFUSFile = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isDownloading, setIsDownloading] = useState({
    fusdata: false,
    filelist: false,
    segdata: false,
    csv: false,
    dicom: false,
    data: false,
    pdf: false
  });

  const { role } = useSelector((state) => state.userInfo.user);

  const isAdmin = role === UserRoles.ADMIN;
  const isSubscriber = role === UserRoles.SUBSCRIBER;

  const [isUploading, setIsUploading] = useState(false);
  const [isSegmenting, setIsSegmenting] = useState(false);
  const [isPostProcessing, setIsPostProcessing] = useState(false);
  const [isRadPDP, setIsRadPDP] = useState(false);
  const [isFUSUploading, setIsFUSUploading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const viewRender = () => {
    const url = `${PARAVIEW_URL}?name=g.glance&url=${BASE_URL}/render/${medicalCase.id}`;
    console.log('url');
    window.open(url, '_blank');
  };

  const stateText =
    medicalCase?.state &&
    medicalCase?.state?.toUpperCase() === 'UPLOAD COMPLETE'
      ? 'PROCESS PENDING'
      : medicalCase?.state?.toUpperCase();

  const handleDownloadFile = async (medicalCaseId, type) => {
    setIsDownloading((prevState) => ({ ...prevState, [type]: true }));
    downloadFile(medicalCaseId, type)
    .then(() => {
      enqueueSnackbar(
        'Your File has been dowloaded succesfully.',
        { variant: 'info' }
      );
    })
    .catch((e) => {
      console.error(e?.response?.data?.message);
      enqueueSnackbar('Error dowloading File.', { variant: 'error' });
    })
    .finally(() => {
      setIsDownloading((prevState) => ({ ...prevState, [type]: false }));
    });
  };

  const handleUploadedFile = async (event) => {
    const file = event.target.files[0];
    setMriFile(file);
    setIsUploading(true);
    uploadSegData(medicalCase?.id, file)
    .then(() => {
      enqueueSnackbar(
        'Your SEG has been upload succesfully.',
        { variant: 'info' }
      );
    })
    .catch((e) => {
      console.error(e?.response?.data?.message);
      enqueueSnackbar('Error uploading SEG.', { variant: 'error' });
    })
    .finally(() => {
      setIsUploading(false);
    });
  };

  const handleUploadedFUSFile = async (event) => {
    const file = event.target.files[0];
    setFUSFile(file);
    setIsFUSUploading(true);
    uploadFUSData(medicalCase?.id, file)
    .then(() => {
      enqueueSnackbar(
        'Your FUS has been upload succesfully.',
        { variant: 'info' }
      );
    })
    .catch((e) => {
      console.error(e?.response?.data?.message);
      enqueueSnackbar('Error uploading FUS.', { variant: 'error' });
    })
    .finally(() => {
      setIsFUSUploading(false);
    });
  };

  const onUploadButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onUploadFUSButtonClick = () => {
    // `current` points to the mounted file input element
    inputFUSFile.current.click();
  };

  const onRunSegButtonClick = () => {
    // `current` points to the mounted file input element
    setIsSegmenting(true);
    runSegData(medicalCase?.id)
    .then(() => {
      enqueueSnackbar(
        'Automatic segmentation is running under the hood, check log in 2 minutes.',
        { variant: 'info' }
      );
    })
    .catch((e) => {
      console.error(e?.response?.data?.message);
      enqueueSnackbar('Error running segmentation, try again please.', { variant: 'error' });
    })
    .finally(() => {
      setIsSegmenting(false);
    });
  };

  const onRunProcessorButtonClick = () => {
    // `current` points to the mounted file input element
    setIsPostProcessing(true);
    runProcessor(medicalCase?.id)
    .then(() => {
      enqueueSnackbar(
        'Post processing pipeline is running under the hood, check log in 2 minutes.',
        { variant: 'info' }
      );
    })
    .catch((e) => {
      console.error(e?.response?.data?.message);
      enqueueSnackbar('Error running post processing pipeline, try again please.', { variant: 'error' });
    })
    .finally(() => {
      setIsPostProcessing(false);
    });
  };

  const onRunRadPDPButtonClick = () => {
    // `current` points to the mounted file input element
    setIsRadPDP(true);
    runRadPDP(medicalCase?.id)
    .then(() => {
      enqueueSnackbar(
        'Rad to PDP is running under the hood, check log in 2 minutes.',
        { variant: 'info' }
      );
    })
    .catch((e) => {
      console.error(e?.response?.data?.message);
      enqueueSnackbar('Error running Rad to PDP, try again please.', { variant: 'error' });
    })
    .finally(() => {
      setIsRadPDP(false);
    });
  };

  const onDeleteButtonClick = () => {
    // `current` points to the mounted file input element
    runDelete(medicalCase?.id);
    onClose();
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        justifyContent: 'center',
        padding: '30px'
      }}
    >
      <Container maxWidth="sm">
        <Box sx={{ mb: 2 }}>
          <Typography color="textPrimary" variant="h2">
            Case Information
          </Typography>
          {/* <Typography color="textSecondary" gutterBottom variant="body2">
                </Typography> */}
        </Box>
        <Grid item sm={12} xs={12}>
          <Grid container alignItems="center" justifyContent="space-around">
            {isAdmin && (
              <Grid item sm={12} xs={12}>
                <TextWithLabel
                  title="Username"
                  text={medicalCase?.username || '-'}
                />
              </Grid>
            )}
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel title="Name" text={medicalCase?.name || '-'} />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel title="Case-ID" text={medicalCase?.id} />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel title="Gender" text={medicalCase?.gender} />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel title="Age" text={medicalCase?.age} />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel title="Side" text={medicalCase?.weight} />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel
                title="Upload date"
                text={
                  medicalCase.creationDate
                    ? new Date(medicalCase?.creationDate)?.toDateString()
                    : 'NA'
                }
                disabled={!medicalCase?.creationDate}
              />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel
                title="Processed date"
                text={
                  medicalCase.processedDate
                    ? new Date(medicalCase?.processedDate)?.toDateString()
                    : 'NA'
                }
                disabled={!medicalCase?.processedDate}
              />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel
                title="Acquisition date"
                text={
                  medicalCase.acquisitionDate
                    ? new Date(medicalCase?.acquisitionDate)?.toDateString()
                    : 'NA'
                }
                disabled={!medicalCase?.acquisitionDate}
              />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel
                title="Status"
                text={stateText || 'NA'}
                disabled={!stateText}
              />
            </Grid>
            <Grid sx={{ marginBottom: '1rem' }} item sm={6} xs={12}>
              <TextWithLabel
                title="Sequences"
                text={medicalCase?.diagnostic || '-'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            py: 2,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Grid item xs={12}>
            <ButtonGroup
              color="primary"
              variant="contained"
              disabled={medicalCase?.state === 'upload pending'}
              fullWidth
              orientation={isMobile ? 'vertical' : 'horizontal'}
            >
              {(isAdmin || isSubscriber) && (
                <Button
                  onClick={() => handleDownloadFile(medicalCase?.id, 'filelist')}
                  disabled={isDownloading.filelist}
                  fullWidth={isMobile}
                >
                  {isDownloading.filelist ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Download style={{ marginRight: '1rem' }} />
                      GET LOG
                    </Box>
                  )}
                </Button>
              )}
              {isAdmin && (
                <Button
                  onClick={onRunSegButtonClick}
                  disabled={isSegmenting || medicalCase?.state === 'upload pending'}
                  fullWidth={isMobile}
                >
                  {isSegmenting ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      RUN SEG
                    </Box>
                  )}
                </Button>
              )}
              {(isAdmin || isSubscriber) && (
                <Button
                  onClick={() => handleDownloadFile(medicalCase?.id, 'segdata')}
                  disabled={isDownloading.segdata || medicalCase?.state === 'upload pending' || medicalCase?.state === 'processed side 01' || medicalCase?.state === 'processed side 02'}
                  fullWidth={isMobile}
                >
                  {isDownloading.segdata ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Download style={{ marginRight: '1rem' }} />
                      GET SEG
                    </Box>
                  )}
                </Button>
              )}
              <input
                type="file"
                id="file"
                ref={inputFile}
                accept=".tar.gz,.zip"
                multiple={false}
                style={{ display: 'none' }}
                onChange={handleUploadedFile}
              />
              {(isAdmin || isSubscriber) && (
                <Button
                  onClick={onUploadButtonClick}
                  disabled={isUploading || medicalCase?.state === 'upload pending' || medicalCase?.state === 'processed side 01' || medicalCase?.state === 'processed side 02'}
                  fullWidth={isMobile}
                >
                  {isUploading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Publish style={{ marginRight: '1rem' }} />
                      PUT SEG
                    </Box>
                  )}
                </Button>
              )}
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <ButtonGroup
              color="primary"
              variant="contained"
              fullWidth
              orientation={isMobile ? 'vertical' : 'horizontal'}
            >
              {isAdmin && (
                <Button
                  onClick={onRunProcessorButtonClick}
                  disabled={isPostProcessing || medicalCase?.state === 'upload pending' || medicalCase?.state === 'processed side 01' || medicalCase?.state === 'processed side 02'}
                  fullWidth={isMobile}
                >
                  {isPostProcessing ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      RUN PPL
                    </Box>
                  )}
                </Button>
              )}
              {(isAdmin || !isAdmin) && (
                <Button
                  onClick={() => handleDownloadFile(medicalCase?.id, 'data')}
                  disabled={isDownloading.data || medicalCase?.state !== 'processed side 04'}
                  fullWidth={isMobile}
                >
                  {isDownloading.data ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Download style={{ marginRight: '1rem' }} />
                      REPORT
                    </Box>
                  )}
                </Button>
              )}
              {(isAdmin || !isAdmin) && (
              <Button
                onClick={viewRender}
                fullWidth={isMobile}
                disabled={medicalCase?.state !== 'processed side 04' && medicalCase?.state !== 'processed'}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Visibility style={{ marginRight: '1rem' }} />
                  3D MAP
                </Box>
              </Button>
              )}
              {(isAdmin || !isAdmin) && (
              <Button
                color="secondary"
                onClick={onClose}
                fullWidth={isMobile}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  CLOSE
                </Box>
              </Button>
              )}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup
              color="primary"
              variant="contained"
              fullWidth
              orientation={isMobile ? 'vertical' : 'horizontal'}
            >
              {isAdmin && (
              <Button
                onClick={onDeleteButtonClick}
                disabled={medicalCase?.state === 'processed side 04' || medicalCase?.state === 'processed'}
                fullWidth={isMobile}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  DELETE
                </Box>
              </Button>
              )}
              {isAdmin && (
                <Button
                  onClick={onRunRadPDPButtonClick}
                  disabled={isRadPDP || medicalCase?.state === 'upload pending' || medicalCase?.state === 'upload pending' || medicalCase?.state === 'processed side 01' || medicalCase?.state === 'processed side 02'}
                  fullWidth={isMobile}
                >
                  {isRadPDP ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      RAD PDP
                    </Box>
                  )}
                </Button>
              )}
              {isAdmin && (
                <Button
                  onClick={() => handleDownloadFile(medicalCase?.id, 'fusdata')}
                  disabled={isDownloading.fusdata || medicalCase?.state !== 'processed side 04'}
                  fullWidth={isMobile}
                >
                  {isDownloading.fusdata ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Download style={{ marginRight: '1rem' }} />
                      GET FUS
                    </Box>
                  )}
                </Button>
              )}
              <input
                type="file"
                id="file"
                ref={inputFUSFile}
                accept=".tar.gz,.zip"
                multiple={false}
                style={{ display: 'none' }}
                onChange={handleUploadedFUSFile}
              />
              {isAdmin && (
                <Button
                  onClick={onUploadFUSButtonClick}
                  disabled={isFUSUploading || medicalCase?.state === 'upload pending' || medicalCase?.state === 'processed side 01' || medicalCase?.state === 'processed side 02' || medicalCase?.state === 'processed side 03'}
                  fullWidth={isMobile}
                >
                  {isFUSUploading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Publish style={{ marginRight: '1rem' }} />
                      PUT FUS
                    </Box>
                  )}
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

MedicalCaseInfo.propTypes = {
  onClose: PropTypes.func,
  medicalCase: PropTypes.object
};

export default MedicalCaseInfo;
