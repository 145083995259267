import {
  SET_MEDICAL_CASES,
  ADD_MEDICAL_CASE,
  SET_IS_LOADING,
  SET_FILTER_PATIENT_NAME,
  SET_FILTER_USERNAME,
  SET_FILTER_DATE_INTERVAL,
  SET_IS_DIRTY
} from 'redux/medical_cases/medical_cases.constants';

const initialState = {
  casesList: [],
  isLoading: true,
  filterPatientName: '',
  filterUsername: '',
  dateFilterInterval: {
    initialDate: undefined,
    finalDate: undefined
  },
  isDirty: true
};

const medicalCasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.payload.isDirty,
        filterValue: '',
        dateFilterInterval: {
          initialDate: undefined,
          finalDate: undefined
        }
      };
    case SET_MEDICAL_CASES:
      return {
        ...state,
        casesList: action.payload.medicalCases
      };
    case ADD_MEDICAL_CASE:
      return {
        ...state,
        casesList: [action.payload.medicalCase, ...state.casesList].slice(
          0,
          action.payload.isFree ? 3 : state.casesList.length + 1
        )
      };
    case SET_FILTER_PATIENT_NAME:
      return {
        ...state,
        filterPatientName: action.payload.filterPatientName
      };
    case SET_FILTER_USERNAME:
      return {
        ...state,
        filterUsername: action.payload.filterUsername
      };
    case SET_FILTER_DATE_INTERVAL:
      return {
        ...state,
        dateFilterInterval: {
          initialDate: action.payload.initialDate,
          finalDate: action.payload.finalDate
        }
      };
    default:
      return state;
  }
};

export default medicalCasesReducer;
