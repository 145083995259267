import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { forgotPassword } from 'services/http/auth';
import { useSnackbar } from 'notistack';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { email: paramsEmail } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const handleForgotPassword = async (email) =>
    forgotPassword(email)
      .then(() => {
        enqueueSnackbar('Check your email for the reset code.', {
          variant: 'info'
        });
        navigate('/change-password', { state: { email }, replace: true });
      })
      // TODO: move password validation to frontend
      .catch((e) => {
        if (e.response?.data?.message) {
          enqueueSnackbar(e.response.data.message, {
            variant: 'error',
            autoHideDuration: 6000
          });
        } else {
          enqueueSnackbar('An error ocurred. Please try again.', {
            variant: 'error'
          });
        }
      });

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: paramsEmail || ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required')
            })}
            onSubmit={({ email }) => handleForgotPassword(email)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                onSubmit={async (data) => {
                  await handleSubmit(data);
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Forgot your password?
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Don&apos;t worry, we will send you a code via email to
                    change your password.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Send Code
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Did you remember your password?
                  <Link component={RouterLink} to="/login" variant="h6">
                    {' '}
                    Back to login
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
