import { Navigate } from 'react-router-dom';
import DashboardLayout from 'components/DashboardLayout';
import MainLayout from 'components/MainLayout';

import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import MedicalCaseList from 'pages/MedicalCase';
import Register from 'pages/Register';
import ActivateAccount from 'pages/ActivateAccount';
import ForgotPassword from 'pages/ForgotPassword';
import ChangePassword from 'pages/ChangePassword';
import ChangeRole from 'pages/ChangeRole';

const routes = (isLoggedIn) => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'medical_cases', element: <MedicalCaseList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'forgot-password/:email', element: <ForgotPassword /> },
      { path: 'upgrade/:token', element: <ChangeRole action="upgrade" /> },
      { path: 'downgrade/:token', element: <ChangeRole action="downgrade" /> },
      { path: 'change-password', element: <ChangePassword /> },
      { path: 'activate', element: <ActivateAccount /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/medical_cases" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
