import axios from 'services/http/_axios';

import {
  SET_USER,
  SET_USER_ROLE,
  SET_IS_LOGGED_IN,
  SET_TOKEN_EXPIRED,
  LOGOUT
} from 'redux/user/user.constants';
import { SET_IS_DIRTY } from 'redux/medical_cases/medical_cases.constants';

export const setUser = (user) => (dispatch) => {
  if (user) {
    axios.defaults.headers.common.Authorization = `Bearer ${user?.token}`;
    localStorage.setItem('medx_user', JSON.stringify(user));
  }
  dispatch({
    type: SET_USER,
    payload: { user }
  });
};

export const setRole = (newRole) => (dispatch, getState) => {
  const {
    userInfo: { user }
  } = getState();
  const updatedUser = { ...user, role: newRole };
  localStorage.setItem('medx_user', JSON.stringify(updatedUser));
  dispatch({
    type: SET_USER_ROLE,
    payload: { role: newRole }
  });
};

export const setIsLoggedIn = (isLoggedIn) => (dispatch) => {
  dispatch({
    type: SET_IS_LOGGED_IN,
    payload: { isLoggedIn }
  });
};

export const setTokenExpired = (tokenExpired) => (dispatch) => {
  dispatch({
    type: SET_TOKEN_EXPIRED,
    payload: { tokenExpired }
  });
};

export const logout = () => (dispatch) => {
  localStorage.setItem('medx_user', undefined);
  dispatch({
    type: LOGOUT
  });
  dispatch({
    type: SET_IS_DIRTY,
    payload: { isDirty: true }
  });
};
