const isValidDate = (date) => date instanceof Date && !Number.isNaN(date);

export const isValidDateInterval = (initialDate, finalDate) => {
  const startDate = new Date(initialDate);
  if (!isValidDate(startDate)) return false;
  const endDate = new Date(finalDate);
  if (!isValidDate(endDate)) return false;

  const now = new Date();

  return startDate <= now && endDate <= now && startDate <= endDate;
};
