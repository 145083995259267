import { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography } from '@material-ui/core';
import MedicalCaseCard from 'components/medical_case/MedicalCaseCard';
import { getMedicalCases } from 'services/http/medical_case';

import { useDispatch, useSelector } from 'react-redux';
import {
  setMedicalCases,
  setIsLoading,
  setIsDirty
} from 'redux/medical_cases/medical_cases.actions';

import LoadingCard from 'components/ui/LoadingCard';
import MedicalCaseInfo from 'components/medical_case/MedicalCaseInfo';
import CenteredModal from 'components/ui/CenteredModal';

const isMedicalCaseFiltered = (
  medicalCase,
  filterPatientName,
  filterUsername,
  filterInitialDate,
  filterFinalDate
) => {
  const filterValue = (property, value) => property.toLowerCase().includes(value.toLowerCase());
  if (!filterValue(medicalCase.name, filterPatientName)) return false;
  if (!filterValue(medicalCase.username, filterUsername)) return false;

  if (filterInitialDate && filterFinalDate) {
    let initialDate = new Date(filterInitialDate);
    const userTimezoneOffset = initialDate.getTimezoneOffset() * 60000;
    initialDate = new Date(initialDate.getTime() + userTimezoneOffset);

    let finalDate = new Date(filterFinalDate);
    finalDate = new Date(finalDate.getTime() + userTimezoneOffset);
    finalDate.setDate(finalDate.getDate() + 1);

    const medicalCaseDate = new Date(medicalCase.creationDate);

    return initialDate <= medicalCaseDate && medicalCaseDate <= finalDate;
  }
  return true;
};

const MedicalCaseList = () => {
  const {
    isLoading,
    casesList,
    filterPatientName,
    filterUsername,
    dateFilterInterval: { initialDate, finalDate },
    isDirty
  } = useSelector((state) => state.medicalCases);

  const [selectedCase, setSelectedCase] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDirty) {
      dispatch(setIsLoading(true));
      getMedicalCases()
        .then((data) => {
          dispatch(setMedicalCases(data));
          dispatch(setIsDirty(false));
        })
        .finally(() => dispatch(setIsLoading(false)));
    }
  }, [isDirty]);

  if (isLoading) {
    return <LoadingCard />;
  }

  if (casesList.length === 0) {
    return (
      <Card style={{ marginTop: 20 }}>
        <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{ maxWidth: 500 }}
            alignContent="center"
            justifyContent="center"
          >
            <Typography> There are no cases </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ pt: 3 }}>
      <Grid container spacing={3}>
        {casesList.map(
          (medicalCase) =>
            isMedicalCaseFiltered(
              medicalCase,
              filterPatientName,
              filterUsername,
              initialDate,
              finalDate
            ) && (
              <Grid item key={medicalCase.id} lg={12} md={12} xs={12}>
                <MedicalCaseCard
                  medicalCase={medicalCase}
                  onClick={() => setSelectedCase(medicalCase)}
                />
              </Grid>
            )
        )}
      </Grid>
      <CenteredModal
        open={!!selectedCase}
        onClose={() => setSelectedCase(null)}
      >
        <MedicalCaseInfo medicalCase={selectedCase || {}} />
      </CenteredModal>
    </Box>
  );
};

export default MedicalCaseList;
