import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export const TextWithLabel = ({ title, text, disabled }) => (
  <div>
    <Typography color="GrayText" gutterBottom variant="body2">
      {title}
    </Typography>
    <Typography
      color={disabled ? 'GrayText' : 'textPrimary'}
      gutterBottom
      variant="h6"
    >
      {text}
    </Typography>
  </div>
);

TextWithLabel.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool
};
