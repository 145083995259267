import React from 'react';
import { Card, CardContent, Box, CircularProgress } from '@material-ui/core';

const LoadingCard = () => (
  <Card style={{ marginTop: 20 }}>
    <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ maxWidth: 500 }} alignContent="center" justifyContent="center">
        <CircularProgress />
      </Box>
    </CardContent>
  </Card>
);

export default LoadingCard;
