import { combineReducers } from 'redux';
import medicalCasesReducer from './medical_cases/medical_cases.reducer';
import userReducer from './user/user.reducer';

const rootReducer = combineReducers({
  medicalCases: medicalCasesReducer,
  userInfo: userReducer
});

export default rootReducer;
