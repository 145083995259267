import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  Button,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { Activity as ActivityIcon } from 'react-feather';

import InputIcon from '@material-ui/icons/Input';
import { useSnackbar } from 'notistack';

import { requestUpgrade, requestDowngrade } from 'services/http/auth';

import { logout } from 'redux/user/user.actions';
import { UserRoles } from 'constants/userRoles';

import NavItem from 'components/NavItem';

const items = [
  {
    href: '/app/medical_cases',
    icon: ActivityIcon,
    title: 'Medical Cases'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userInfo);

  const [isRequestingRoleChange, setIsRequestingRoleChange] = useState(false);

  const isAdminAccount = user.role === UserRoles.ADMIN;
  const isFreeAccount = user.role === UserRoles.FREE;
  const buttonLabel = isFreeAccount ? 'Upgrade' : 'Downgrade';

  const handleChangeRole = () => {
    const changeRoleFunc = isFreeAccount ? requestUpgrade : requestDowngrade;
    setIsRequestingRoleChange(true);
    changeRoleFunc()
      .then(() => {
        enqueueSnackbar(
          'Your role change request has been submitted. You will be contacted via email.',
          {
            variant: 'info'
          }
        );
      })
      .catch(() => {
        enqueueSnackbar(
          'An error ocurred while submitting your role change request. Please try again.',
          {
            variant: 'error'
          }
        );
      })
      .finally(() => setIsRequestingRoleChange(false));
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Box
          width="80%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography component="span" color="textPrimary" variant="h5">
            {user.name}
          </Typography>
          <Typography component="span" color="textSecondary" variant="body2">
            {user.role?.toUpperCase()}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {user.email}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      {!isAdminAccount && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            m: 1,
            p: 1
          }}
        >
          <Typography align="center" gutterBottom variant="h4">
            {isFreeAccount ? 'Need more?' : 'Back to free?'}
          </Typography>
          {isFreeAccount && (
            <Typography px={2} align="center" variant="body2">
              Subscribe to TensorCare platform to get aditional features.
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 1
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleChangeRole}
              disabled={isRequestingRoleChange}
            >
              {isRequestingRoleChange ? (
                <CircularProgress size={24} />
              ) : (
                buttonLabel
              )}
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1
        }}
      >
        <IconButton color="inherit" onClick={handleLogout}>
          <InputIcon />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
