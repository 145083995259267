import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    overflow: 'scroll',
    height: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    zIndex: 2000
  }
}));

const CenteredModal = ({ open, onClose, children }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {React.cloneElement(children, { onClose: handleClose })}
        </div>
      </Fade>
    </Modal>
  );
};

CenteredModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
};

export default CenteredModal;
