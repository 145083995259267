import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'components/GlobalStyles';
import theme from 'theme';
import routes from 'routes';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const isLoggedIn = useSelector((state) => state.userInfo.isLoggedIn);
  const routing = useRoutes(routes(isLoggedIn));

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <GlobalStyles />
        {routing}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
