import axios from 'axios';
import { setIsLoggedIn, setTokenExpired } from 'redux/user/user.actions';

import { Store } from 'redux/store';

const axiosInstace = axios.create();

const stringData = localStorage.getItem('medx_user');
const user = stringData !== 'undefined' ? JSON.parse(stringData) : undefined;
if (user) {
  axiosInstace.defaults.headers.common.Authorization = `Bearer ${user?.token}`;
}

axiosInstace.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      Store.dispatch(setIsLoggedIn(false));
      Store.dispatch(setTokenExpired(true));
    }
    return Promise.reject(error);
  }
);

export default axiosInstace;
