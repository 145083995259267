import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { confirmUpgrade, confirmDowngrade } from 'services/http/auth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const ChangeRole = ({ action }) => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [isChanging, setIsChanging] = useState(false);

  const confirmFuncs = {
    upgrade: confirmUpgrade,
    downgrade: confirmDowngrade
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeRole = async () => {
    const confirm = confirmFuncs[action];
    setIsChanging(true);
    confirm(token)
      .then(() => {
        enqueueSnackbar(`Account ${action}d.`, {
          variant: 'info'
        });
        navigate('/login', { replace: true });
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          enqueueSnackbar(e.response.data.message, {
            variant: 'error',
            autoHideDuration: 6000
          });
        } else {
          enqueueSnackbar('An error ocurred. Please try again.', {
            variant: 'error'
          });
        }
      })
      .finally(() => setIsChanging(false));
  };

  return (
    <>
      <Helmet>
        <title>Change Role</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ mb: 3 }}>
            <Typography color="textPrimary" variant="h2">
              Confirm role change.
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              {`Click the button below to ${action} the account.`}
            </Typography>
          </Box>
          <Box sx={{ py: 2 }}>
            <Button
              color="primary"
              disabled={isChanging}
              fullWidth
              size="large"
              variant="contained"
              onClick={handleChangeRole}
            >
              Confirm Change
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

ChangeRole.propTypes = {
  action: PropTypes.oneOf(['downgrade', 'upgrade'])
};

export default ChangeRole;
