import {
  SET_USER,
  SET_USER_ROLE,
  SET_USER_ROLE_CHANGE_PENDING,
  SET_IS_LOGGED_IN,
  SET_TOKEN_EXPIRED,
  LOGOUT
} from './user.constants';

const stringData = localStorage.getItem('medx_user');
const user = stringData !== 'undefined' ? JSON.parse(stringData) : undefined;
const initialState = {
  user,
  isLoggedIn: !!user
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user
      };
    case SET_USER_ROLE:
      return {
        ...state,
        user: { ...state.user, role: action.payload.role }
      };
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn
      };
    case SET_TOKEN_EXPIRED:
      return {
        ...state,
        tokenExpired: action.payload.tokenExpired
      };
    case LOGOUT:
      return {
        isLoggedIn: false
      };
    default:
      return state;
  }
};

export default userReducer;
