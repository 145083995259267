import {
  SET_MEDICAL_CASES,
  ADD_MEDICAL_CASE,
  SET_IS_LOADING,
  SET_FILTER_PATIENT_NAME,
  SET_FILTER_USERNAME,
  SET_FILTER_DATE_INTERVAL,
  SET_IS_DIRTY
} from 'redux/medical_cases/medical_cases.constants';
import { UserRoles } from 'constants/userRoles';

export const setMedicalCases = (medicalCases) => (dispatch) => {
  dispatch({
    type: SET_MEDICAL_CASES,
    payload: { medicalCases }
  });
};

export const addMedicalCase = (medicalCase) => (dispatch, getState) => {
  const { role } = getState().userInfo.user;
  dispatch({
    type: ADD_MEDICAL_CASE,
    payload: { medicalCase, isFree: role === UserRoles.FREE }
  });
};

export const setIsLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING,
    payload: { isLoading }
  });
};

export const setIsDirty = (isDirty) => (dispatch) => {
  dispatch({
    type: SET_IS_DIRTY,
    payload: { isDirty }
  });
};

export const setFilterPatientName = (filterPatientName) => (dispatch) => {
  dispatch({
    type: SET_FILTER_PATIENT_NAME,
    payload: { filterPatientName }
  });
};

export const setFilterUsername = (filterUsername) => (dispatch) => {
  dispatch({
    type: SET_FILTER_USERNAME,
    payload: { filterUsername }
  });
};

export const setFilterDateInterval = ({ initialDate, finalDate }) => (
  dispatch
) => {
  dispatch({
    type: SET_FILTER_DATE_INTERVAL,
    payload: { initialDate, finalDate }
  });
};
