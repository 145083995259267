import axios from './_axios';

const { REACT_APP_BASE_URL: BASE_URL } = process.env;
const URL = `${BASE_URL}/medical-cases`;

export const uploadMedicalCase = (medicalCase) =>
  axios
    .post(URL, medicalCase)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const getMedicalCases = () =>
  axios
    .get(URL)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const runDelete = (medicalCaseId) =>
  axios
    .delete(`${URL}/${medicalCaseId}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const uploadMRI = (medicalCaseId, mriFile) => {
  const formData = new FormData();
  formData.append('file', mriFile);
  return axios
    .post(
      `${URL}/upload/${medicalCaseId}`,
      formData
      // , {
      //   onUploadProgress: (progressEvent) => {
      //     const totalLength = progressEvent.lengthComputable
      //       ? progressEvent.total
      //       : progressEvent.target.getResponseHeader('content-length') ||
      //         progressEvent.target.getResponseHeader(
      //           'x-decompressed-content-length'
      //         );
      //     console.log('onUploadProgress', totalLength);
      //     if (totalLength !== null) {
      //       console.log({ loaded: progressEvent.loaded, total: totalLength });
      //       onProgressChange(
      //         Math.round((progressEvent.loaded * 100) / totalLength)
      //       );
      //     }
      //   }
      // }
    )
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));
};

export const uploadSegData = (medicalCaseId, segDataFile) => {
  const formData = new FormData();
  formData.append('file', segDataFile);
  return axios
    .post(
      `${URL}/upload_segdata/${medicalCaseId}`,
      formData
    )
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));
};

export const uploadFUSData = (medicalCaseId, FUSDataFile) => {
  const formData = new FormData();
  formData.append('file', FUSDataFile);
  return axios
    .post(
      `${URL}/upload_fusdata/${medicalCaseId}`,
      formData
    )
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));
};

export const runSegData = (medicalCaseId) =>
  axios
    .post(`${URL}/apply-sm-endpoint/${medicalCaseId}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const runProcessor = (medicalCaseId) =>
  axios
    .post(`${URL}/run_processor/${medicalCaseId}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const runRadPDP = (medicalCaseId) =>
  axios
    .post(`${URL}/run_radpdp/${medicalCaseId}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const getFile = (medicalCaseId, type) => {
  if (!['fusdata', 'filelist', 'segdata', 'csv', 'dicom', 'data', 'pdf'].includes(type)) {
    const message = 'Invalid type specified to download file.';
    console.error(message);
    return Promise.reject(message);
  }
  return axios
    .get(`${URL}/${type}/${medicalCaseId}`, { responseType: 'blob' })
    .then((res) => res)
    .catch((e) => Promise.reject(e));
};
