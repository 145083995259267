import axios from './_axios';

const { REACT_APP_BASE_URL: BASE_URL } = process.env;
const URL = `${BASE_URL}/auth`;

// Auth
export const login = (email, password) =>
  axios
    .post(`${URL}/login`, { email, password })
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const register = (email, password, name) =>
  axios
    .post(`${URL}/register`, { email, password, name })
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

// Activate account
export const confirmAccount = (email, verificationCode) =>
  axios
    .post(`${URL}/confirm`, { email, verificationCode })
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const resendVerification = (email) =>
  axios
    .post(`${URL}/resendVerification/${email}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

// Password
export const confirmPassword = (email, password, code) =>
  axios
    .post(`${URL}/confirmPassword`, { email, password, code })
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const forgotPassword = (email) =>
  axios
    .post(`${URL}/forgotPassword/${email}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

// Roles
export const requestUpgrade = () =>
  axios
    .post(`${URL}/upgrade-request`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const requestDowngrade = () =>
  axios
    .post(`${URL}/downgrade-request`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const confirmUpgrade = (token) =>
  axios
    .post(`${URL}/upgrade/${token}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));

export const confirmDowngrade = (token) =>
  axios
    .post(`${URL}/downgrade/${token}`)
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));
