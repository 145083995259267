import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import {
  Assignment,
  AssignmentLate,
  AssignmentTurnedIn
} from '@material-ui/icons';

import { UserRoles } from 'constants/userRoles';

import { TextWithLabel } from '../ui/TextWithLabel';

const MedicalCaseCard = ({ medicalCase, ...rest }) => {
  // const classes = useStyles();
  const { role } = useSelector((state) => state.userInfo.user);

  const cardIcons = {
    'upload pending': Assignment,
    'upload complete': Assignment,
    'upload error': AssignmentLate,
    'in process': Assignment,
    'processed side 04': AssignmentTurnedIn,
    pending: Assignment,
    error: AssignmentLate,
    processed: AssignmentTurnedIn
  };

  const iconColors = {
    'upload pending': 'disabled',
    'upload complete': 'disabled',
    'upload error': 'error',
    'in process': 'disabled',
    'processed side 04': 'primary',
    processed: 'primary',
    error: 'error',
    pending: 'disabled'
  };

  const stateText =
    medicalCase?.state &&
    medicalCase?.state?.toUpperCase() === 'UPLOAD COMPLETE'
      ? 'PROCESS PENDING'
      : medicalCase?.state?.toUpperCase();

  const IconComponent = cardIcons[medicalCase?.state] || Assignment;

  return (
    <Paper
      sx={{
        display: 'flex',
        height: '100%',
        padding: '1% 10px',
        cursor: 'pointer'
      }}
      {...rest}
    >
      <Grid container alignItems="center" justifyContent="space-around">
        <Grid item sm={1} xs={12}>
          <Grid container justifyContent="center">
            <IconComponent
              color={iconColors[medicalCase?.state] || 'disabled'}
              fontSize="large"
            />
          </Grid>
        </Grid>
        <Grid item sm={11} xs={12}>
          <Grid container alignItems="top" justifyContent="space-around">
            {role === UserRoles.ADMIN && (
              <Grid item sm={2} xs={12}>
                <TextWithLabel
                  title="Username"
                  text={medicalCase?.username || '-'}
                />
              </Grid>
            )}
            <Grid item sm={2} xs={6}>
              <TextWithLabel title="Name" text={medicalCase?.name || '-'} />
              <TextWithLabel title="Gender" text={medicalCase?.gender} />
            </Grid>
            <Grid item sm={2} xs={6}>
              <TextWithLabel title="Age" text={medicalCase?.age} />
              <TextWithLabel title="Side" text={medicalCase?.weight} />
            </Grid>
            <Grid item sm={2} xs={6}>
              <TextWithLabel
                title="Upload date"
                text={
                  medicalCase?.creationDate
                    ? new Date(medicalCase?.creationDate)?.toDateString()
                    : 'NA'
                }
                disabled={!medicalCase?.creationDate}
              />
              <TextWithLabel
                title="Processed date"
                text={
                  medicalCase?.processedDate
                    ? new Date(medicalCase?.processedDate)?.toDateString()
                    : 'NA'
                }
                disabled={!medicalCase?.processedDate}
              />
            </Grid>
            <Grid item sm={2} xs={6}>
              <TextWithLabel
                title="Acquisition date"
                text={
                  medicalCase?.acquisitionDate
                    ? new Date(medicalCase?.acquisitionDate)?.toDateString()
                    : 'NA'
                }
                disabled={!medicalCase?.acquisitionDate}
              />
              <TextWithLabel
                title="Status"
                text={stateText || 'NA'}
                disabled={!medicalCase?.state}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

MedicalCaseCard.propTypes = {
  medicalCase: PropTypes.object.isRequired
};

export default MedicalCaseCard;
